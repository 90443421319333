@import url(https://fonts.googleapis.com/css2?family=Lora:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: left;
  background-color: #0E0E0E;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  color: white;
  padding: 72px;
  background-image: url(/static/media/BG_3.bb8b87b6.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  -ms-background-size: cover;
  background-size: cover;
  background-attachment: scroll;

}

.Main-header{
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: space-between;
}

.Container {
  min-height: 80vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: center;
  color: white;
  padding: 72px;
}

.Container img{
  width: 100%;
}

.Container-inner-left{
  box-sizing: border-box;
  width: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  color: white;
}

.Container-inner-right{
  width: 50%;
}

.Container-inner-left h3{
  box-sizing: border-box;
  width:80%
}

.Container-inner-center{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  text-align: center;
}

.Container-inner-full h3{
  box-sizing: border-box;
  width:40%
}

.Client-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 40px;
}


.Client-image{
  padding-right: 32px;
  padding-top: 24px;
}

@media only screen and (max-width: 600px) {
  .App-header{
    min-height: 90vh;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    color: white;
    padding: 24px;
    background-image: url(/static/media/BG_3.bb8b87b6.jpg);
    background-repeat: no-repeat;
    background-position: 50% 0;
    -ms-background-size: cover;
    background-size: cover;
    background-attachment: scroll;
  }
  .Container {
    min-height: 80vh;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    color: white;
    padding: 32px;
  }
  
  .Container-inner-left{
    box-sizing: border-box;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    color: white;
  }
  
  .Container-inner-right{
    width: 100%;
  }
  .Container-inner-left h3{
    box-sizing: border-box;
    width:100%
  }
  
  .Container-inner-full h3{
    box-sizing: border-box;
    width:100%
  }
  .Client-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
  }
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


h1{
  font-size: 52px;
  font-family: 'Lora', serif;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 6px;

}

h2{
  font-size: 40px;
  font-family: 'Lora', serif;
  font-weight: 400;
}

h3{
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: #959594;
  margin-top: 8px;
  margin-bottom: 16px;


}

h3 span{
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #fff;


}

h4{
  font-size: 12px;
  font-family: 'Lora', serif;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 8px;
}

h4 span{
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 8px;

}

h5{
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 28px;
  margin-top: 8px;
  margin-bottom: 8px;

}

h6 {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  margin-top: 8px;

}

@media only screen and (max-width: 600px) {
  h1{
    font-size: 32px;
    font-family: 'Lora', serif;
    font-weight: 400;
  }
  
  h4{
    font-size: 14px;
    font-family: 'Lora', serif;
    font-weight: 800;
    font-style: italic;
  }
  h4 span{
    font-size: 14px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
  }
  h3{
    font-size: 26px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    color: #959594;
    margin-top: 8px;
    margin-bottom: 16px;
  
  }
  
  h3 span{
    font-size: 26px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: #fff;
  
  
  }
}


